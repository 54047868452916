//TODO: to clean up

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "custom/bootstrap.min.js"
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("trix") // actiontext editor
require("@rails/actiontext")
require("@nathanvda/cocoon")
global.toastr = require("toastr")

//TOASTR
import "../stylesheets/application"


// import "custom/datepicker.js"



